export const getZoomLevel = (lat, lng, isPrecise) => {
  const isNYC = lat > 40.5 && lat < 41 && lng > -74.5 && lng < -73.5;
  const isLA = lat > 33 && lat < 34 && lng > -119 && lng < -117;
  if (isPrecise) return isNYC ? 12 : 11;
  return isNYC || isLA ? 10 : 9;
};

export const degreesPerPixel = (zoomLevel: number) => {
  // 360° divided by the number of pixels at the zoom-level
  return 360 / (Math.pow(2, zoomLevel) * 256);
};

export const calculateZoomLevel = (
  mapWidth: number,
  mapHeight: number,
  viewport,
) => {
  const GLOBE_WIDTH = 256; // a constant in Google Maps for tile size in pixels
  const ZOOM_MAX = 21; // maximum zoom level

  const ne = viewport.getNorthEast();
  const sw = viewport.getSouthWest();

  // Calculate distance in longitude
  const latDiff = ne.lat() - sw.lat();
  const lngDiff = Math.abs(ne.lng() - sw.lng());
  const adjustedLngDiff = lngDiff > 180 ? 360 - lngDiff : lngDiff;

  // Handle edge cases for zero differences
  if (latDiff === 0 || adjustedLngDiff === 0) {
    return ZOOM_MAX; // Default to max zoom if viewport is essentially a point or line
  }

  const latFraction = latDiff / 180;
  const lngFraction = adjustedLngDiff / 360;

  // Calculate latitude and longitude scale
  const lngZoom = Math.log(mapWidth / GLOBE_WIDTH / lngFraction) / Math.LN2;
  const latZoom = Math.log(mapHeight / GLOBE_WIDTH / latFraction) / Math.LN2;

  // Return the smaller of the two, capped at the max zoom level
  return Math.min(lngZoom, latZoom, ZOOM_MAX) + 1;
};
